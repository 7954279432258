<template>
  <div class="ForgotPasswordView">
    <div v-if="forgotPasswordEmailSent">
      <div class="o-container-gray">
        <img src="@/assets/images/checkmark.png"
            alt="Checkmark image"
            class="o-image-medium" />
        <h3 class="o-default-h3">{{ $t('forgotPasswordView.forgotPasswordEmailSent') }} <span class="u-strong">{{ userEmail }}</span>.</h3>
        <h4 class="o-default-h4">{{ $t('forgotPasswordView.accessforgotPasswordLink') }}</h4>
        <h4 class="o-default-h4 navigate-to-main-page">{{ $t('forgotPasswordView.navigateToMainPage') }} </h4>
        <md-button class="md-raised md-primary navigate-btn"
                   @click="Helpers.goToRoute('', $router)">
          {{ $t('forgotPasswordView.mainPage') }}
        </md-button>
      </div>
    </div>
    <div v-else>
      <div class="o-container-gray">
        <form novalidate class="md-layout" @submit.prevent="validateEmail">
          <md-card>
            <md-progress-bar md-mode="indeterminate" v-if="isAuthLoading" />
            <md-card-header>
              <div class="md-title">{{ $t('forgotPasswordView.title') }}</div>
            </md-card-header>

            <md-card-content>
              <p class="o-default-body forgot-password-info">{{ $t('forgotPasswordView.info') }}</p>
              <md-field md-clearable :class="getValidationClass('email')">
                <label for="email">{{ $t('common.email') }}</label>
                <md-input v-model.trim="email"
                          type="email"
                          name="email"
                          id="email"
                          autocomplete="email"></md-input>
                <span v-if="!$v.email.required" class="md-error">{{ $t('common.auth.pleaseEnterEmail') }}</span>
                <span v-else-if="!$v.email.email" class="md-error">{{ $t('common.auth.incorrectEmail') }}</span>
              </md-field>
            </md-card-content>

            <div v-if="errors" v-html="errors" class="u-default-body u-red"></div>

            <vue-recaptcha ref="invisibleRecaptcha"
                           @verify="onVerify"
                           @expired="onExpired"
                           size="invisible"
                           :sitekey="sitekey">
            </vue-recaptcha>

            <md-card-actions class="u-justify-content-center u-text-center">
              <md-button type="submit" class="md-raised md-primary send-btn">{{ $t('common.continue') }}</md-button>
            </md-card-actions>

            <md-card-actions class="u-justify-content-center u-text-center">
              <md-button class="md-raised cancel-btn"
                         @click="Helpers.goToRoute('sign-in', $router)">
                {{ $t('forgotPasswordView.cancel') }}
              </md-button>
            </md-card-actions>
          </md-card>

          <!-- <md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!</md-snackbar> -->
        </form>
      </div>
    </div>

    <p class="o-default-body">{{ $t('forgotPasswordView.ifIssuesEncountered') }} {{ $t('contactEmailInreg') }}</p>

    <SecureLock />
  </div> <!-- /.ForgotPasswordView -->
</template>

<script>
import { mapState } from 'vuex';
import {
  START_AUTH_LOADER,
  FORGOT_PASSWORD,
  RESET_AUTH_ERROR
} from '@/store/actions.type';
import { validationMixin } from 'vuelidate';
import {
  required,
  email
} from 'vuelidate/lib/validators';
import VueRecaptcha from 'vue-recaptcha';
import SecureLock from '@/components/util/SecureLock';

export default {
  name: 'ForgotPasswordView',
  mixins: [validationMixin],
  components: {
    VueRecaptcha,
    SecureLock
  },
  data() {
    return {
      email: '',
      forgotPasswordEmailSent: false,
      userEmail: '',
      snackbar: {
        show: false,
        position: 'center',
        duration: 4000,
        isInfinity: false
      },
      sitekey: '6LehkZMUAAAAAOua6fmkshH_FUe0rkw0v45UXjIS'
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      isAuthLoading: state => state.auth.isAuthLoading
    })
  },
  mounted() {
    this.resetErrors();
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v[fieldName]

      if (field) {
        return { 'md-invalid': field.$invalid && field.$dirty }
      }
    },
    clearForm() {
      this.$v.$reset()
      this.email = null
    },
    validateEmail() {
      this.$v.$touch()
      this.$refs.invisibleRecaptcha.execute();
    },
    sendForgotPasswordEmail(response) {
      this.$store.dispatch(START_AUTH_LOADER);
      this.$store
        .dispatch(FORGOT_PASSWORD, {
          email: this.email,
          captchaToken: response
        })
        .then((data) => {
          this.forgotPasswordEmailSent = true;
          this.userEmail = data.user.email;
        });
    },
    onVerify(response) {
      this.$refs.invisibleRecaptcha.reset();

      if (!this.$v.$invalid) {
        this.sendForgotPasswordEmail(response);
      }
    },
    onExpired() {
      this.$refs.invisibleRecaptcha.reset();

      // eslint-disable-next-line
      console.log('Expired');
    },
    resetErrors() {
      this.$store.dispatch(RESET_AUTH_ERROR);
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
};
</script>

<style lang="scss" scoped>
.ForgotPasswordView {

  .md-card {
    margin-left: auto;
    margin-right: auto;
    padding: 1.6rem;
    width: 66rem;
  }

  .md-field:last-child {
    margin-bottom: 2.4rem;
  }

  .o-container-gray {
    margin-top: 0;
  }

  .o-default-h3,
  .o-default-h4 {
    text-align: center;
  }

  .cancel-btn {
    margin: 1.6rem 0;
    width: 100%;
  }

  .forgot-password-info {
    margin: 3.2rem 0;
    text-align: left;
  }

  .navigate-to-main-page {
    margin-top: 3.2rem;
  }

  .navigate-btn {
    background-color: $palette-primary-color-four !important;
    border-radius: .4rem;
    box-shadow: none;
    font-family: $font-family-heading-regular;
    height: 3.8rem;
    letter-spacing: .1rem;
    margin-bottom: 1.6rem;
    text-transform: none;
  }

  .send-btn {
    background-color: $palette-primary-color-four !important;
    width: 100%;
  }
}
</style>
