<template>
  <div class="CookiesView">
    <div class="o-container-gray">
      <md-card class="md-layout-item">

        <md-card-header>
          <SectionTitle :titleContent="messages.title"/>
        </md-card-header>

        <md-card-content>
          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.description.subtitle"/>
            <TextBlockDefault :textContent="messages.description.content1"/>
            <TextBlockDefault :textContent="messages.description.content2"/>
            <TextBlockDefault :textContent="messages.description.content3"/>
          </div>

          <div class="o-container-subsection">
            <SectionTitle :titleContent="messages.categories.title"/>
            <TextBlockDefault :textContent="messages.categories.content"/>
            <SectionSubtitle :titleContent="messages.categories.mandatory.subtitle"/>
            <TextBlockDefault :textContent="messages.categories.mandatory.content1"/>
            <TextBlockDefault :textContent="messages.categories.mandatory.content2"/>
            <SectionSubtitle :titleContent="messages.categories.performance.subtitle"/>
            <TextBlockDefault :textContent="messages.categories.performance.content1"/>
            <TextBlockDefault :textContent="messages.categories.performance.content2"/>
            <md-list>
              <md-list-item>{{ messages.categories.performance.listItem1 }}</md-list-item>
            </md-list>
            <SectionSubtitle :titleContent="messages.categories.functional.subtitle"/>
            <TextBlockDefault :textContent="messages.categories.functional.content1"/>
            <TextBlockDefault :textContent="messages.categories.functional.content2"/>
            <TextBlockDefault :textContent="messages.categories.functional.content3"/>
            <SectionSubtitle :titleContent="messages.categories.marketing.subtitle"/>
            <TextBlockDefault :textContent="messages.categories.marketing.content1"/>
            <TextBlockDefault :textContent="messages.categories.marketing.content2"/>
            <md-list>
              <md-list-item>{{ messages.categories.marketing.listItem1 }}</md-list-item>
            </md-list>
            <TextBlockDefault :textContent="messages.categories.marketing.content3"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.settings.title"/>
            <TextBlockDefault :textContent="messages.settings.content1"/>
            <TextBlockDefault :textContent="messages.settings.content2"/>
            <md-list>
              <md-list-item v-for="link in links"
                           :key="link.id"
                           :href="link.page"
                           target="_blank">{{ link.text }}
              </md-list-item>
            </md-list>
            <TextBlockDefault :textContent="messages.settings.content3"/>
          </div>
        </md-card-content>
      </md-card>
    </div> <!-- o-container-gray -->
  </div> <!-- /.CookiesView -->
</template>

<script>
import SectionTitle from '@/components/util/SectionTitle';
import SectionSubtitle from '@/components/util/SectionSubtitle';
import TextBlockDefault from '@/components/util/TextBlockDefault';

export default {
  name: 'CookiesView',
  components: {
    SectionTitle,
    SectionSubtitle,
    TextBlockDefault
  },
  data() {
    return {
      links: [
        {
          id: 0,
          text: 'Chrome',
          page: 'https://support.google.com/chrome/answer/95647?hl=en-GB&p=cpn_cookies'
        },
        {
          id: 1,
          text: 'Firefox',
          page: 'https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'
        },
        {
          id: 2,
          text: 'Internet Explorer',
          page: 'https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies'
        },
        {
          id: 3,
          text: 'Opera',
          page: 'https://www.opera.com/help'
        },
        {
          id: 4,
          text: 'Safari',
          page: 'https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac'
        },
      ],
      messages: {
        title: this.$t('cookiesView.title'),
        description: {
          subtitle: this.$t('cookiesView.description.subtitle'),
          content1: this.$t('cookiesView.description.content1'),
          content2: this.$t('cookiesView.description.content2'),
          content3: this.$t('cookiesView.description.content3'),
        },
        categories: {
          title: this.$t('cookiesView.categories.title'),
          content: this.$t('cookiesView.categories.content'),
          mandatory: {
            subtitle: this.$t('cookiesView.categories.mandatory.subtitle'),
            content1: this.$t('cookiesView.categories.mandatory.content1'),
            content2: this.$t('cookiesView.categories.mandatory.content2'),
          },
          performance: {
            subtitle: this.$t('cookiesView.categories.performance.subtitle'),
            content1: this.$t('cookiesView.categories.performance.content1'),
            content2: this.$t('cookiesView.categories.performance.content2'),
            listItem1: this.$t('cookiesView.categories.performance.listItem1'),
          },
          functional: {
            subtitle: this.$t('cookiesView.categories.functional.subtitle'),
            content1: this.$t('cookiesView.categories.functional.content1'),
            content2: this.$t('cookiesView.categories.functional.content2'),
            content3: this.$t('cookiesView.categories.functional.content3'),
          },
          marketing: {
            subtitle: this.$t('cookiesView.categories.marketing.subtitle'),
            content1: this.$t('cookiesView.categories.marketing.content1'),
            content2: this.$t('cookiesView.categories.marketing.content2'),
            listItem1: this.$t('cookiesView.categories.marketing.listItem1'),
            content3: this.$t('cookiesView.categories.marketing.content3'),
          }
        },
        settings: {
          title: this.$t('cookiesView.settings.title'),
          content1: this.$t('cookiesView.settings.content1'),
          content2: this.$t('cookiesView.settings.content2'),
          content3: this.$t('cookiesView.settings.content3'),
        }
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.CookiesView {

  .md-list {
    margin: 1.6rem .8rem;
  }

  .SectionSubtitle {
    margin-left: .8rem;
    padding: .8rem;
  }

  .TextBlockDefault {
    margin-left: .8rem;
    padding: .8rem;
  }
}
</style>
